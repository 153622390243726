<template>
  <v-container class="container--fluid grid-list-md">
    <v-row no-gutters>
      <v-col cols="12" class="my-2">
        <span
          class="font-weight-medium text-body-1 primary--text text--darken-1"
        >
          {{ $t("supplierDeliveryNote.supplierDeliveryNoteAction") }}
        </span>
        <NextAndPreviousCommon
          :nextId="nextId"
          :prevId="prevId"
          :documentId="supplierDeliveryNoteId"
          @idChange="redirectOnNextAndPrevious"
        />
      </v-col>
    </v-row>
    <v-row
      no-gutters
      v-if="
        supplierDeliveryNoteId && !!model.is_locked && model.user_id !== user.id
      "
    >
      <v-col cols="12" class="my-3">
        <span
          class="font-weight-medium text-body-1 primary--text text--darken-1 error--text"
        >
          *{{
            $t("common.documentReadOnlyModeNotice", { userId: model.user.name })
          }}
        </span>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-card class="mx-auto" outlined>
          <v-card-title class="py-1">
            <h5
              style="max-width: 175px"
              :class="
                supplierDeliveryNoteId
                  ? 'px-1 text-truncate font-italic primary--text font-weight-bold'
                  : 'primary--text text--darken-1'
              "
              class="font-weight-regular"
            >
              {{
                supplierDeliveryNoteId && !originModel.title
                  ? `${supplierDeliveryNoteId}.`
                  : $t("route.addSupplierDeliveryNote") ||
                    supplierDeliveryNoteId
                  ? originModel.title &&
                    `${supplierDeliveryNoteId}. ${originModel.title}`
                  : $t("route.addSupplierDeliveryNote")
              }}
            </h5>
            <v-spacer />
            <v-menu
              bottom
              v-if="supplierDeliveryNoteId"
              :close-on-click="true"
              :offset-y="true"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :color="
                    remarksList && remarksList.length > 0
                      ? 'success'
                      : 'primary'
                  "
                  class="ma-2"
                  outlined
                  small
                  @click="remarksDialog = true"
                  :disabled="
                    accessRight.length < 2 && accessRight.includes('show')
                  "
                >
                  {{ $t("purchaseOrder.remarks") }}
                </v-btn>
                <v-btn
                  color="primary"
                  class="ma-2"
                  small
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  :disabled="
                    !valid || (!!model.is_locked && model.user_id !== user.id)
                  "
                >
                  <v-icon left> mdi-menu-down </v-icon>
                  {{ $t("quotation.options") }}
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-if="accessRight.includes('create')"
                  dense
                  class="primary--text"
                  @click="copySupplierDeliveryNote"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small class="primary--text"
                        >mdi-content-copy</v-icon
                      >{{ $t("common.copyDocument") }}</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  dense
                  class="primary--text"
                  @click="actDialog = true"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small class="primary--text"> mdi-pulse </v-icon
                      >{{ $t("quotation.activityLog") }}</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  dense
                  class="primary--text"
                  @click="attachedDocumentsDialog = true"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small class="primary--text"
                        >mdi-file-document</v-icon
                      >{{ $t("employee.attachedDocuments") }}</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  dense
                  :disabled="selectedStatus == 2"
                  v-if="accessRight.includes('delete')"
                  class="error--text"
                  @click="
                    (confirmationDialog = true),
                      (delete_item = supplierDeliveryNoteId)
                  "
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small class="error--text"
                        >mdi-delete-sweep-outline</v-icon
                      >{{ $t("common.delete") }}</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-menu
              bottom
              v-if="supplierDeliveryNoteId && accessRight.includes('create')"
              :close-on-click="true"
              :offset-y="true"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  class="ma-2"
                  small
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  :disabled="
                    !supplierDeliveryNoteItems[0].supplier_delivery_note_id ||
                    !valid ||
                    (!!model.is_locked && model.user_id !== user.id)
                  "
                >
                  <v-icon left> mdi-menu-down </v-icon>
                  {{ $t("order.saveAndCreate") }}
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  dense
                  class="primary--text"
                  @click="saveAndCreate('supplierInvoice')"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small class="primary--text">
                        mdi-note-text-outline
                      </v-icon>
                      {{ $t("route.supplierInvoice") }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-menu
              bottom
              v-if="supplierDeliveryNoteId"
              :close-on-click="true"
              :offset-y="true"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  class="ma-2"
                  small
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  :disabled="
                    !valid || (!!model.is_locked && model.user_id !== user.id)
                  "
                >
                  <v-icon left> mdi-menu-down </v-icon>
                  {{ $t("calendar.calendarEvents") }}
                </v-btn>
              </template>
              <v-list>
                <!-- <LoginGoogle /> -->
                <CommonCalendar
                  :docId="supplierDeliveryNoteId"
                  docType="SupplierDeliveryNote"
                  :model="model"
                />
              </v-list>
            </v-menu>
            <HtmlToPdf
              v-if="supplierDeliveryNoteId"
              class="me-2 mb-1"
              :disabled="
                supplierDeliveryNoteItems[0].supplier_delivery_note_id &&
                valid &&
                !(!!model.is_locked && model.user_id !== user.id)
              "
              :model="originModel"
              type="SupplierDeliveryNotePDF"
            />
            <v-btn
              color="primary"
              small
              :loading="loading"
              :disabled="
                !valid ||
                (isFieldReadable && !saveWarehouse) ||
                (!!model.is_locked && model.user_id !== user.id) ||
                (accessRight.length < 2 && accessRight.includes('show'))
              "
              @click="saveSupplierDeliveryNote"
            >
              {{ $t("order.save") }}
            </v-btn>
          </v-card-title>
          <v-divider />
          <v-card-text class="text-h5 pa-2">
            <FormSkeleton
              v-if="
                (supplierDeliveryNoteId &&
                  Object.keys(supplierDeliveryNoteById).length <= 0) ||
                (copiedSupplierDeliveryNote && copyFormLoading)
              "
            />
            <v-form
              v-else
              ref="form"
              v-model="valid"
              lazy-validation
              :disabled="accessRight.length < 2 && accessRight.includes('show')"
            >
              <Alert :alert.sync="alert" :message="message" />
              <v-row>
                <v-col cols="6">
                  <v-card class="mx-auto" flat>
                    <v-card-title class="py-1 primary--text">
                      <v-icon color="primary">mdi-account-group-outline</v-icon>
                      <h6 class="ms-2 font-weight-regular">
                        {{ $t("purchaseOrder.supplierDetails") }}
                      </h6>
                      <v-spacer />
                      <v-icon
                        v-if="supplier_id"
                        @click="openCustomerAdditionalDetailModel"
                        color="primary"
                        class="float-end"
                        >mdi-eye-outline
                      </v-icon>
                    </v-card-title>
                    <v-divider />
                    <v-card-text>
                      <v-row :dense="true">
                        <v-col cols="12">
                          <v-combobox
                            class="asterisk"
                            autocomplete="new-password"
                            dense
                            cache-items
                            :readonly="isFieldReadable"
                            v-model="selectedCustomer"
                            :items="supplierList.data"
                            item-text="name_1"
                            item-value="id"
                            @change="onSupplierChange"
                            flat
                            :rules="supplierValid"
                            return-object
                            hide-no-data
                            hide-details="auto"
                            required
                            single-line
                            @focus="documentNextAndPrevious()"
                            @blur="documentNextAndPreviousBlur()"
                            @dblclick="redirectOnEditSupplierPage"
                            :search-input.sync="supplierSearch"
                            :label="$t('purchaseOrder.supplier')"
                          >
                            <template v-slot:append-outer>
                              <v-icon
                                color="primary"
                                dense
                                v-if="!isFieldReadable"
                                @click="supplierDialog = true"
                                >mdi-magnify
                              </v-icon>
                            </template>
                            <template v-slot:selection="data">
                              <span @dblclick="redirectOnEditSupplierPage">{{
                                `${data.item.id}. ${data.item.name_1}`
                              }}</span>
                            </template>
                            <template v-slot:item="data">
                              <div
                                class="font-weight-medium"
                                style="
                                  font-size: 0.8125rem;
                                  display: flex;
                                  align-item: center;
                                "
                              >
                                {{ `${data.item.id}. ${data.item.name_1}` }}
                              </div>
                            </template>
                            <template v-slot:append-item>
                              <div
                                v-show="hasSupplierNextPage"
                                v-intersect="infiniteScroll"
                                ref="load"
                                class="loader text-center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="primary"
                                />
                              </div>
                            </template>
                          </v-combobox>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            class="asterisk"
                            autocomplete="new-password"
                            hide-details="auto"
                            :readonly="isFieldReadable"
                            v-model="model.supplier_name"
                            :label="$t('purchaseOrder.supplierName')"
                            :rules="supplierNameValid"
                            required
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            hide-details="auto"
                            :readonly="isFieldReadable"
                            v-model="model.address"
                            :label="$t('purchaseOrder.address')"
                            :rules="[
                              lessThanValidation(
                                $t('purchaseOrder.address'),
                                $t('common.less'),
                                250,
                                model.address
                              ),
                            ]"
                            required
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            hide-details="auto"
                            :readonly="isFieldReadable"
                            v-model="model.town"
                            :label="$t('purchaseOrder.town')"
                            :rules="[
                              lessThanValidation(
                                $t('purchaseOrder.town'),
                                $t('common.less'),
                                250,
                                model.town
                              ),
                            ]"
                            required
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="6">
                  <v-card class="mx-auto" flat>
                    <v-card-title class="py-1 primary--text">
                      <v-icon color="primary"
                        >mdi-file-delimited-outline</v-icon
                      >
                      <h6 class="ms-2 font-weight-regular">
                        {{
                          $t("supplierDeliveryNote.supplierDeliveryNoteDetail")
                        }}
                      </h6>
                      <v-spacer />
                    </v-card-title>
                    <v-divider />
                    <v-card-text>
                      <v-row :dense="true">
                        <v-col cols="12">
                          <v-text-field
                            hide-details="auto"
                            dense
                            :readonly="isFieldReadable"
                            v-model="model.title"
                            :label="$t('purchaseOrder.title')"
                            :rules="titleValid"
                            required
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            readonly
                            hide-details="auto"
                            v-model="model.customer_order_no"
                            @click="redirectToPurchaseOrder"
                            :label="$t('purchaseOrder.purchaseOrderNumber')"
                            :rules="customerOrderNoValid"
                            required
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            hide-details="auto"
                            readonly
                            v-model="model.year"
                            :label="$t('supplierDeliveryNote.year')"
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-menu
                            v-if="!isFieldReadable"
                            ref="startDateMenu"
                            v-model="startDateMenu"
                            :close-on-content-click="false"
                            :return-value.sync="deliveryDateFormat"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                hide-details="auto"
                                v-model="model.delivery_date"
                                :label="$t('supplierDeliveryNote.deliveryDate')"
                                @blur="
                                  deliveryDateFormat = profileById.date_format
                                    ? parseDateYYYYMMDD(model.delivery_date)
                                    : model.delivery_date
                                "
                                v-bind="attrs"
                                v-on="on"
                                readonly
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="deliveryDateFormat"
                              @change="
                                $refs.startDateMenu.save(deliveryDateFormat)
                              "
                            >
                              <v-btn
                                small
                                class="primary"
                                @click="$refs.startDateMenu.save(todayDate())"
                                >{{ $t("route.today") }}</v-btn
                              >
                            </v-date-picker>
                          </v-menu>
                          <v-text-field
                            v-else
                            hide-details="auto"
                            v-model="model.delivery_date"
                            :label="$t('supplierDeliveryNote.deliveryDate')"
                            readonly
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            hide-details="auto"
                            :readonly="isFieldReadable"
                            v-model="model.received_by"
                            :label="$t('supplierDeliveryNote.receivedBy')"
                            :rules="receivedByRequired"
                            required
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            hide-details="auto"
                            :readonly="isFieldReadable"
                            v-model="model.delivery_note_no"
                            :label="$t('supplierDeliveryNote.deliveryNoteNo')"
                            :rules="deliveryNoteNoRequired"
                            required
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-combobox
                            autocomplete="new-password"
                            :readonly="isFieldReadable"
                            v-model="selectedEmployee"
                            :items="employeeList.data"
                            item-text="name"
                            item-value="id"
                            @change="onEmployeeChange"
                            :clearable="!isFieldReadable"
                            flat
                            return-object
                            hide-no-data
                            hide-details="auto"
                            required
                            @dblclick="redirectOnEditEmployeePage"
                            :search-input.sync="employeeSearch"
                            :label="$t('purchaseOrder.agent')"
                          >
                            <template v-slot:append-outer>
                              <v-icon
                                class="mt-1"
                                color="primary"
                                dense
                                v-if="!isFieldReadable"
                                @click="employeeDialog = true"
                              >
                                mdi-magnify
                              </v-icon>
                            </template>
                            <template v-slot:item="data">
                              <div
                                class="font-weight-medium"
                                style="
                                  font-size: 0.8125rem;
                                  display: flex;
                                  align-item: center;
                                "
                              >
                                {{ `${data.item.id}. ${data.item.name}` }}
                              </div>
                            </template>
                            <template v-slot:append-item>
                              <div
                                v-show="hasEmployeeNextPage"
                                v-intersect="infiniteScrollOnEmployee"
                                ref="load"
                                class="loader text-center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="primary"
                                />
                              </div>
                            </template>
                          </v-combobox>
                        </v-col>
                        <v-col cols="4">
                          <v-select
                            :readonly="selectedStatus == 2"
                            v-model="selectedWarehouse"
                            :items="warehouse2"
                            item-text="description"
                            item-value="id"
                            return-object
                            :rules="warehouseValid"
                            :menu-props="{ bottom: true, offsetY: true }"
                            :label="$t('delivery.field.warehouse')"
                            @change="changeWarehouse"
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12">
                  <v-card class="mx-auto" flat>
                    <v-card-title class="py-1 primary--text">
                      <v-row dense no-gutters>
                        <v-col cols="10" class="d-flex">
                          <v-icon color="primary">mdi-cart-plus</v-icon>
                          <h6
                            class="ms-2 align-self-center font-weight-regular"
                          >
                            {{ $t("order.listOfItems") }}
                          </h6>
                        </v-col>
                        <v-col cols="2" class="text-end font-weight-regular">
                          <SuppliedQuantityDialog
                            :id="supplierDeliveryNoteId"
                            @suppliedQty="suppliedQty"
                          />
                        </v-col>
                      </v-row>
                    </v-card-title>
                    <v-divider />
                    <v-card-text>
                      <v-row :dense="true">
                        <v-col cols="12">
                          <v-simple-table
                            dense
                            class="custom-table elevation-1 item-table"
                            fixed-header
                            height="250"
                          >
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th :style="{ width: '40px' }">
                                    {{ $t("order.no") }}
                                  </th>
                                  <th :style="{ width: '220px' }">
                                    {{ $t("order.product") }}
                                  </th>
                                  <th :style="{ width: '180px' }">
                                    {{ $t("order.itemDescription") }}
                                  </th>
                                  <th :style="{ width: '50px' }">
                                    {{ $t("order.quantity") }}
                                  </th>
                                  <th
                                    v-if="
                                      !supplierDeliveryNoteId ||
                                      (supplierDeliveryNoteId &&
                                        accessRight.includes('financial'))
                                    "
                                    class="text-center"
                                    :style="{ width: '100px' }"
                                  >
                                    {{ $t("order.unitPrice") }}
                                  </th>
                                  <th
                                    v-if="
                                      !supplierDeliveryNoteId ||
                                      (supplierDeliveryNoteId &&
                                        accessRight.includes('financial'))
                                    "
                                    :style="{ width: '50px' }"
                                  >
                                    {{ $t("order.lineDiscount") }}
                                  </th>
                                  <th
                                    v-if="!supplierDeliveryNoteId"
                                    class="text-center"
                                    :style="{ width: '100px' }"
                                  >
                                    <span
                                      v-if="
                                        ($route.params.copiedModel &&
                                          $route.params.copiedModel
                                            .including_vat) ||
                                        ($route.query.supplierDeliveryNoteId &&
                                          supplierDeliveryNoteById.including_vat)
                                      "
                                    >
                                      {{
                                        $t("company.field.totalIncludingVAT")
                                      }}
                                    </span>
                                    <span
                                      v-else-if="
                                        ($route.params.copiedModel &&
                                          !$route.params.copiedModel
                                            .including_vat) ||
                                        ($route.query.supplierDeliveryNoteId &&
                                          !supplierDeliveryNoteById.including_vat)
                                      "
                                    >
                                      {{ $t("order.total") }}
                                    </span>
                                    <span
                                      v-else-if="
                                        !profileById.supplier_delivery_notes &&
                                        !$route.query.supplierDeliveryNoteId
                                      "
                                    >
                                      {{ $t("order.total") }}
                                    </span>
                                    <span
                                      v-else-if="
                                        profileById.supplier_delivery_notes &&
                                        !$route.query.supplierDeliveryNoteId
                                      "
                                    >
                                      {{
                                        $t("company.field.totalIncludingVAT")
                                      }}
                                    </span>
                                  </th>
                                  <th
                                    v-else
                                    class="text-center"
                                    :style="{ width: '100px' }"
                                  >
                                    {{
                                      !model.including_vat
                                        ? $t("order.total")
                                        : $t("company.field.totalIncludingVAT")
                                    }}
                                  </th>
                                  <th
                                    class="text-center"
                                    :style="{ width: '80px' }"
                                  >
                                    {{ $t("order.action") }}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <template
                                  v-for="(
                                    item, index
                                  ) in supplierDeliveryNoteItems"
                                >
                                  <template
                                    v-if="
                                      index === 0 ||
                                      (item.same_doc_id !==
                                        supplierDeliveryNoteItems[index - 1]
                                          .same_doc_id &&
                                        item.item_code !== '*')
                                    "
                                  >
                                    <tr
                                      v-if="item.source_document_number"
                                      :key="'new_line_' + item.same_doc_id"
                                      :style="'background-color: #CFD8DC'"
                                    >
                                      <td colspan="1"></td>
                                      <td><span>-</span></td>
                                      <td colspan="6">
                                        <span>
                                          <b>{{ $t("order.field.docNo") }}:</b>
                                          {{ item.source_document_number
                                          }}<br />
                                        </span>
                                        <span>
                                          <b
                                            >{{ $t("order.field.docDate") }}:</b
                                          >
                                          {{
                                            item.source_document_date
                                              ? item.source_document_date.split(
                                                  "T"
                                                )[0]
                                              : ""
                                          }}<br />
                                        </span>
                                        <span v-if="item.source_document_title">
                                          <b
                                            >{{
                                              $t("order.field.docTitle")
                                            }}:</b
                                          >
                                          {{ item.source_document_title }}<br />
                                        </span>
                                        <span
                                          v-if="
                                            item.source_document_customer_order_no
                                          "
                                        >
                                          <b
                                            >{{
                                              $t("order.field.customerOrderNo")
                                            }}:</b
                                          >
                                          {{
                                            item.source_document_customer_order_no
                                          }}<br />
                                        </span>
                                      </td>
                                    </tr>
                                  </template>
                                  <tr
                                    style="vertical-align: bottom"
                                    :key="index"
                                    :class="[
                                      'yourRowClass',
                                      index + 1 ===
                                      supplierDeliveryNoteItems.length
                                        ? 'newRow'
                                        : '',
                                      {
                                        disabled:
                                          $route.query.purchaseOrderId &&
                                          !model.id,
                                      },
                                      item.item_code === '*' &&
                                      item.doc_discount
                                        ? 'yellow accent-2'
                                        : '',
                                      {
                                        disabled:
                                          item.item_code === '*' &&
                                          item.doc_discount,
                                      },
                                    ]"
                                  >
                                    <td>{{ index + 1 }}</td>
                                    <td>
                                      <span
                                        v-if="!isFieldReadable"
                                        class="d-flex"
                                      >
                                        <product-item-select
                                          :selectedCustomer="selectedCustomer"
                                          :table="'SupplierDeliveryNote'"
                                          :key="index"
                                          :itemId="item.item_id"
                                          :item="item"
                                          :index="index"
                                          @onProductChange="onProductChange"
                                        />
                                        <template>
                                          <v-icon
                                            class="mt-1"
                                            color="primary"
                                            dense
                                            v-if="!isFieldReadable"
                                            @click="
                                              (productDialog = true),
                                                (productDialogIndex = index)
                                            "
                                            :disabled="
                                              accessRight.length < 2 &&
                                              accessRight.includes('show')
                                            "
                                          >
                                            mdi-magnify
                                          </v-icon>
                                        </template>
                                      </span>
                                      <span
                                        v-else
                                        @dblclick="
                                          redirectOnEditProductPage(
                                            item.item_id
                                          )
                                        "
                                        >{{ item.item_code }}</span
                                      >
                                    </td>
                                    <td>
                                      <v-textarea
                                        v-if="!item.doc_discount"
                                        dense
                                        rows="1"
                                        :readonly="isFieldReadable"
                                        hide-details="auto"
                                        v-model="item.item_description"
                                        :title="item.item_description"
                                        :disabled="
                                          !item.selectedProduct ? true : false
                                        "
                                        :rules="[
                                          lessThanValidation(
                                            $t('order.itemDescription'),
                                            $t('common.less'),
                                            4000,
                                            item.item_description
                                          ),
                                        ]"
                                        required
                                      />
                                      <v-textarea
                                        v-else
                                        dense
                                        rows="1"
                                        :readonly="isFieldReadable"
                                        hide-details="auto"
                                        :placeholder="
                                          $t('common.documentDiscount')
                                        "
                                      />
                                    </td>
                                    <td>
                                      <v-text-field
                                        dense
                                        :readonly="isFieldReadable"
                                        hide-details="auto"
                                        v-model="item.quantity"
                                        :disabled="
                                          !item.selectedProduct ? true : false
                                        "
                                        :rules="quantityValid"
                                        required
                                        @input="itemPriceCalculation(index)"
                                      >
                                        <template v-if="item.quantity === 0">{{
                                          (item.quantity = null)
                                        }}</template>
                                      </v-text-field>
                                    </td>
                                    <td
                                      v-if="
                                        !supplierDeliveryNoteId ||
                                        (supplierDeliveryNoteId &&
                                          accessRight.includes('financial'))
                                      "
                                    >
                                      <v-text-field
                                        dense
                                        :readonly="isFieldReadable"
                                        :name="'unit_price' + index"
                                        :ref="'unit_price' + index"
                                        :reverse="
                                          locale === 'he' ? false : true
                                        "
                                        hide-details="auto"
                                        v-model="item.unit_price"
                                        :disabled="
                                          !item.selectedProduct ? true : false
                                        "
                                        :rules="unitPriceValid"
                                        required
                                        @input="itemPriceCalculation(index)"
                                      >
                                        <template
                                          v-if="item.unit_price === 0"
                                          >{{
                                            (item.unit_price = null)
                                          }}</template
                                        >
                                      </v-text-field>
                                    </td>
                                    <td
                                      v-if="
                                        !supplierDeliveryNoteId ||
                                        (supplierDeliveryNoteId &&
                                          accessRight.includes('financial'))
                                      "
                                    >
                                      <v-text-field
                                        dense
                                        :readonly="isFieldReadable"
                                        hide-details="auto"
                                        v-model="item.line_discount"
                                        :disabled="
                                          !item.selectedProduct ? true : false
                                        "
                                        :rules="discountValid"
                                        required
                                        @input="itemPriceCalculation(index)"
                                      >
                                        <template v-slot:append>
                                          <v-icon
                                            class="mt-2"
                                            x-small
                                            color="primary"
                                            >mdi-percent-outline</v-icon
                                          >
                                        </template>
                                        <template
                                          v-if="item.line_discount === 0"
                                          >{{
                                            (item.line_discount = null)
                                          }}</template
                                        >
                                      </v-text-field>
                                    </td>
                                    <td
                                      v-if="
                                        !supplierDeliveryNoteId ||
                                        (supplierDeliveryNoteId &&
                                          accessRight.includes('financial'))
                                      "
                                      :class="
                                        locale === 'he'
                                          ? 'text-start'
                                          : 'text-end'
                                      "
                                    >
                                      <v-text-field
                                        dense
                                        :readonly="isFieldReadable"
                                        :name="'total_price' + index"
                                        :ref="'total_price' + index"
                                        :reverse="
                                          locale === 'he' ? false : true
                                        "
                                        hide-details="auto"
                                        v-model="item.total"
                                        :disabled="
                                          !item.selectedProduct ? true : false
                                        "
                                        :rules="unitPriceValid"
                                        required
                                        @input="itemPriceCalculation(index)"
                                      >
                                        <template v-if="item.total === 0">{{
                                          (item.total = null)
                                        }}</template>
                                      </v-text-field>
                                    </td>
                                    <td class="text-center">
                                      <v-row>
                                        <v-col cols="4" class="text-end">
                                          <v-btn
                                            :disabled="
                                              isFieldReadable ||
                                              (accessRight.length < 2 &&
                                                accessRight.includes('show'))
                                            "
                                            icon
                                            color="primary"
                                            small
                                            @click="addRow"
                                          >
                                            <v-icon color="primary"
                                              >mdi-plus</v-icon
                                            >
                                          </v-btn>
                                        </v-col>
                                        <v-col cols="4" class="text-end">
                                          <v-btn
                                            icon
                                            :disabled="
                                              isFieldReadable ||
                                              (accessRight.length < 2 &&
                                                accessRight.includes('show')) ||
                                              !item.selectedProduct
                                            "
                                            color="primary"
                                            x-small
                                            class="mt-1"
                                            @click="copyData(index)"
                                          >
                                            <v-icon color="primary"
                                              >mdi-content-copy</v-icon
                                            >
                                          </v-btn>
                                        </v-col>
                                        <v-col cols="4" class="text-start">
                                          <v-btn
                                            icon
                                            :disabled="
                                              isFieldReadable ||
                                              (accessRight.length < 2 &&
                                                accessRight.includes('show'))
                                            "
                                            color="error"
                                            small
                                            @click="deleteRow(index)"
                                          >
                                            <v-icon color="error"
                                              >mdi-delete-sweep-outline</v-icon
                                            >
                                          </v-btn>
                                        </v-col>
                                      </v-row>
                                    </td>
                                  </tr>
                                </template>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-col>
                      </v-row>
                      <v-row :dense="true" class="pt-6">
                        <v-col cols="8">
                          <v-row :dense="true">
                            <v-col cols="8">
                              <v-row>
                                <v-col cols="6">
                                  <v-radio-group
                                    hide-no-data
                                    hide-details="auto"
                                    dense
                                    single-line
                                    v-model="selectedStatus"
                                    :disabled="isFieldReadable"
                                    class="text-caption pt-0 mt-3"
                                  >
                                    <template v-slot:label>
                                      <div>
                                        {{ $t("purchaseOrder.status") }}
                                      </div>
                                    </template>
                                    <v-radio
                                      v-for="statusItem in statusList"
                                      :key="statusItem.id"
                                      :label="`${statusItem.name}`"
                                      :value="statusItem.id"
                                    ></v-radio>
                                  </v-radio-group>
                                  <v-autocomplete
                                    :class="
                                      relatedPurchaseOrderItems.length > 0
                                        ? 'accent lighten-3'
                                        : ''
                                    "
                                    cache-items
                                    :items="relatedPurchaseOrderItems"
                                    item-text="related_doc_number"
                                    item-value="related_doc_number"
                                    @change="redirectOnEditPurchaseOrderPage"
                                    flat
                                    return-object
                                    hide-no-data
                                    hide-details="auto"
                                    required
                                    single-line
                                    :label="
                                      $t('supplierDeliveryNote.purchaseOrderNo')
                                    "
                                  />
                                </v-col>
                                <v-col cols="6">
                                  <v-autocomplete
                                    :class="
                                      relatedSupplierInvoiceItems.length > 0
                                        ? 'accent lighten-3'
                                        : ''
                                    "
                                    cache-items
                                    :items="relatedSupplierInvoiceItems"
                                    item-text="related_doc_number"
                                    item-value="related_doc_number"
                                    @change="redirectOnEditSupplierInvoicePage"
                                    flat
                                    return-object
                                    hide-no-data
                                    hide-details="auto"
                                    required
                                    single-line
                                    :label="$t('supplierInvoice.invoiceNo')"
                                  />
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col cols="4">
                              <v-row>
                                <v-col cols="12">
                                  <v-textarea
                                    hide-details="auto"
                                    :readonly="isFieldReadable"
                                    dense
                                    v-model="model.remarks"
                                    :label="$t('purchaseOrder.remarks')"
                                    :rules="[
                                      lessThanValidation(
                                        $t('purchaseOrder.remarks'),
                                        $t('common.less'),
                                        1000,
                                        model.remarks
                                      ),
                                    ]"
                                  />
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-divider class="mx-8" vertical />
                        <v-col
                          cols="3"
                          v-if="
                            !supplierDeliveryNoteId ||
                            (supplierDeliveryNoteId &&
                              accessRight.includes('financial'))
                          "
                        >
                          <v-row dense>
                            <v-col cols="6" class="font-weight-bold">{{
                              $t("purchaseOrder.total")
                            }}</v-col>
                            <v-col
                              cols="6"
                              class="font-weight-bold"
                              :class="
                                locale === 'he' ? 'text-start' : 'text-end'
                              "
                            >
                              {{ numberWithCommas(model.total) }}
                            </v-col>
                          </v-row>
                          <v-row dense>
                            <v-col cols="6">
                              <v-row dense no-gutters>
                                <v-col cols="6">{{
                                  $t("purchaseOrder.discount")
                                }}</v-col>
                                <v-col cols="6">
                                  <v-text-field
                                    height="18"
                                    :readonly="isFieldReadable"
                                    class="ma-0 pa-0"
                                    hide-details="auto"
                                    dense
                                    :rules="discountValid"
                                    @input="
                                      supplierDeliveryNotePriceCalculation
                                    "
                                    v-model="model.discount_percentage"
                                  >
                                    <template v-slot:append>
                                      <v-icon x-small color="primary"
                                        >mdi-percent-outline</v-icon
                                      >
                                    </template>
                                  </v-text-field>
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col
                              cols="6"
                              :class="
                                locale === 'he' ? 'text-start' : 'text-end'
                              "
                            >
                              {{ numberWithCommas(model.discount) }}
                            </v-col>
                          </v-row>
                          <v-row dense>
                            <v-col cols="6" class="font-weight-bold">{{
                              $t("purchaseOrder.afterDiscount")
                            }}</v-col>
                            <v-col
                              cols="6"
                              class="font-weight-bold"
                              :class="
                                locale === 'he' ? 'text-start' : 'text-end'
                              "
                            >
                              {{ numberWithCommas(model.after_discount) }}
                            </v-col>
                          </v-row>
                          <v-row dense>
                            <v-col cols="6">
                              <v-row dense no-gutters>
                                <v-col cols="6">{{
                                  $t("purchaseOrder.vat")
                                }}</v-col>
                                <v-col cols="6">
                                  <v-text-field
                                    :disabled="includeVatCharge ? false : true"
                                    height="18"
                                    :readonly="isFieldReadable"
                                    class="ma-0 pa-0"
                                    hide-details="auto"
                                    dense
                                    :rules="vatPrecentageValid"
                                    @input="
                                      supplierDeliveryNotePriceCalculation
                                    "
                                    v-model="model.vat_percentage"
                                  >
                                    <template v-slot:append>
                                      <v-icon x-small color="primary"
                                        >mdi-percent-outline</v-icon
                                      >
                                    </template>
                                  </v-text-field>
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col
                              cols="6"
                              :class="
                                locale === 'he' ? 'text-start' : 'text-end'
                              "
                            >
                              {{ numberWithCommas(model.vat) }}
                            </v-col>
                          </v-row>
                          <v-row dense>
                            <v-col cols="6" class="font-weight-bold">{{
                              $t("purchaseOrder.grandTotal")
                            }}</v-col>
                            <v-col
                              cols="6"
                              class="font-weight-bold"
                              :class="
                                locale === 'he' ? 'text-start' : 'text-end'
                              "
                            >
                              {{ numberWithCommas(model.grand_total) }}
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <v-overlay :z-index="999" v-if="isProgressCircular">
        <v-icon size="64" class="hourglass-icon" color="primary"
          >mdi-timer-sand-paused</v-icon
        >
      </v-overlay>
      <ActivityLogTableDialog
        :actDialog.sync="actDialog"
        :docId.sync="supplierDeliveryNoteId"
        docType="SupplierDeliveryNote"
        docMode="purchase"
        docItems="SupplierDeliveryNoteItems"
        docEmailSent="SupplierDeliveryNoteEmailSent"
      />
      <ProductDialog
        @onProductChange="onProductChange"
        v-if="supplier_id"
        :dialog.sync="productDialog"
        :productDialogIndex="productDialogIndex"
      />
      <CustomerDetail
        :model.sync="customerAdditionalDetail"
        :isFieldReadable="isFieldReadable"
        @saveCustomerAdditionalDetail="saveCustomerAdditionalDetail"
        :customerDetailDialog.sync="customerDetailDialog"
      />
      <EmployeeDialog
        @onEmployeeChange="onEmployeeChange"
        :dialog.sync="employeeDialog"
      />
      <SupplierDialog
        @onSupplierChange="onSupplierChange"
        :dialog.sync="supplierDialog"
      />
      <RemarksDialog
        :dialog.sync="remarksDialog"
        :status="originModel.status"
        :docId="supplierDeliveryNoteId"
        :docType="94"
      />
      <AttachedDocumentsDialog
        :docId="supplierDeliveryNoteId"
        docType="SupplierDeliveryNote"
        :dialog.sync="attachedDocumentsDialog"
      />
      <confirmation-dialog
        :dialog.sync="confirmationDialog"
        :delete_item.sync="delete_item"
        @deleteItem="deleteItem"
      />
      <DeliveryNoteNoConfirmationDialog
        :dialog.sync="deliveryNoteNoDialog"
        @confirmDialog="onChangeDialog"
        type="SupplierDeliveryNote"
      />
      <SuppliedDialog :dialog.sync="suppliedDialogBox" />
      <RemainingSupplied
        :dialog.sync="remainingSuppliedDialogBox"
        :supplied="model.supplierDeliveryNoteItems"
        doc="supplierDeliveryNote"
        :docId="model.id"
        :model="model"
      />
      <CancelNotDialog
        :dialog.sync="cancelNotDialog"
        type="supplierDeliveryNote"
      />
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import {
  changedFormData,
  parseDateYYYYMMDD,
  formatDateDDMMYYYY,
  todayDate,
  getAuxiliaryZtableValueByName,
} from "@/utils";
import FormSkeleton from "@/components/skeleton/FormSkeleton";
import Alert from "@/components/Alert";
import ProductDialog from "@/components/moduleTableDialog/Dialog/ProductDialog";
import ProductItemSelect from "../../../components/ProductItemSelect.vue";
import SupplierDialog from "@/components/moduleTableDialog/Dialog/SupplierDialog";
import ActivityLogTableDialog from "@/components/activityLog/Dialogs/ActivityLogTableDialog.vue";
import CustomerDetail from "@/components/moduleTableDialog/Dialog/CustomerDetail";
import EmployeeDialog from "@/components/moduleTableDialog/Dialog/EmployeeDialog";
import HtmlToPdf from "@/components/generatePDF/HtmlToPdf";
import RemarksDialog from "@/components/RemarksDialog";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import AttachedDocumentsDialog from "@/components/AttachedDocumentsDialog";
import DeliveryNoteNoConfirmationDialog from "@/components/DeliveryNoteNoConfirmationDialog";
import SuppliedDialog from "@/components/SupliedDialog";
import RemainingSupplied from "@/components/RemainingSuppliedDialog";
import CancelNotDialog from "@/components/CancelNotDialog";
import NextAndPreviousCommon from "@/components/NextAndPreviousCommon";
import SuppliedQuantityDialog from "@/components/SuppliedQuantityDialog";
import CommonCalendar from "@/components/CommonCalendar";
import { validationMixin } from "@/mixins/validationMixin";
import { intersectionMixin } from "@/mixins/intersectionMixin";
import { searchMixin } from "@/mixins/searchMixin";

export default {
  name: "SupplierDeliveryNoteAction",
  components: {
    FormSkeleton,
    Alert,
    ProductDialog,
    CustomerDetail,
    EmployeeDialog,
    ActivityLogTableDialog,
    SupplierDialog,
    HtmlToPdf,
    ProductItemSelect,
    RemarksDialog,
    ConfirmationDialog,
    AttachedDocumentsDialog,
    DeliveryNoteNoConfirmationDialog,
    SuppliedDialog,
    RemainingSupplied,
    CancelNotDialog,
    NextAndPreviousCommon,
    SuppliedQuantityDialog,
    CommonCalendar,
  },
  mixins: [validationMixin, intersectionMixin, searchMixin],
  data() {
    return {
      message: "",
      customerDetailDialog: false,
      alert: false,
      loading: false,
      valid: false,
      customerAdditionalDetail: {},
      model: {
        status: 0,
        total: 0.0,
        discount: 0.0,
        after_discount: 0.0,
        vat: 0.0,
        grand_total: 0.0,
      },
      originModel: {},
      selectedCustomer: null,
      supplierDialog: false,
      selectedEmployee: null,
      employeeDialog: false,
      actDialog: false,
      startDateMenu: false,
      deliveryDateFormat: "",
      customerOrderNoRequired: false,
      relatedPurchaseOrderItems: [],
      supplierDeliveryNoteItems: [
        {
          selectedProduct: null,
          unit_price: null,
          item_description: null,
          total: null,
        },
      ],
      supplier_id: null,
      supplierLimit: 10,
      employeePage: 1,
      employeeLimit: 10,
      productDialog: false,
      productDialogIndex: null,
      selectedStatus: 0,
      selectedProduct: [],
      copyFormLoading: true,
      nextId: null,
      prevId: null,
      remarksDialog: false,
      confirmationDialog: false,
      delete_item: null,
      attachedDocumentsDialog: false,
      isProgressCircular: false,
      deliveryNoteNoDialog: false,
      warehouse2: [],
      selectedWarehouse: null,
      suppliedDialogBox: false,
      remainingSuppliedDialogBox: false,
      relatedSupplierInvoiceItems: [],
      cancelNotDialog: false,
      saveWarehouse: false,
      includeVatCharge: false,
      itemDiscount: null,
    };
  },
  computed: {
    computedDateFormatted() {
      return this.profileById.date_format
        ? this.formatDateDDMMYYYY(this.deliveryDateFormat)
        : this.deliveryDateFormat;
    },
    statusList() {
      if (this.model.status == 2) {
        return [
          { name: this.$t("order.field.open"), id: 0 },
          { name: this.$t("order.field.finished"), id: 1 },
          { name: this.$t("order.field.canceled"), id: 2 },
        ];
      } else {
        return [
          { name: this.$t("order.field.open"), id: 0 },
          { name: this.$t("order.field.finished"), id: 1 },
        ];
      }
    },
    warehouseValid() {
      return [
        (v) =>
          !(
            this.profileById.warehouse_inventory_management &&
            this.profileById.warehouse_complusory
          ) ||
          !!v ||
          this.$t("formRules.warehouseRequired"),
      ];
    },
    deliveryNoteNoRequired() {
      return [
        (v) => !!v || this.$t("formRules.deliveryNoteNoRequired"),
        (v) =>
          !v ||
          /^[0-9]*$/.test(v) ||
          this.$t("formRules.deliveryNoteNoInteger"),
        (v) =>
          !v ||
          v.toString().length < 9 ||
          this.$t("formRules.lengthValidMessage", {
            fieldName: this.$t("supplierDeliveryNote.deliveryNoteNo"),
            type: this.$t("common.less"),
            size: 8,
          }),
      ];
    },
    receivedByRequired() {
      return [
        (v) =>
          !v || !/^\s*$/.test(v) || this.$t("formRules.receivedByRequired"),
        (v) =>
          !v ||
          (v && v.length <= 15) ||
          this.$t("formRules.lengthValidMessage", {
            fieldName: this.$t("supplierDeliveryNote.receivedBy"),
            type: this.$t("common.less"),
            size: 15,
          }),
      ];
    },
    discountValid() {
      return [
        (v) =>
          !v ||
          /^\d{0,8}(\.\d{1,4})?$/.test(v) ||
          this.$t("formRules.spaceValidValue"),
        (v) =>
          !v ||
          (v && String(v).length <= 11) ||
          this.$t("formRules.lengthValidMessage", {
            fieldName: this.$t("purchaseOrder.discount"),
            type: this.$t("common.less"),
            size: 11,
          }),
      ];
    },
    supplierDeliveryNoteId() {
      return this.$route.params.supplier_delivery_note_id;
    },
    copiedSupplierDeliveryNote() {
      return this.$route.params.copiedModel;
    },
    ...mapGetters({
      supplierDeliveryNoteById: "supplierDeliveryNote/supplierDeliveryNoteById",
      locale: "locale",
      profileById: "profile/profileById",
      employeeById: "employee/employeeById",
      supplierList: "supplier/supplierList",
      remarksList: "remarks/remarksList",
      supplierById: "supplier/supplierById",
      quotationById: "quotation/quotationById",
      employeeList: "employee/employeeList",
      supplierDeliveryNoteQuery:
        "supplierDeliveryNote/supplierDeliveryNoteQuery",
      accessRight: "accessRight",
      purchaseOrderById: "purchaseOrder/purchaseOrderById",
      user: "user",
      moreThanOneUserLogout: "moreThanOneUserLogout",
    }),
    isFieldReadable() {
      return this.model.status == 0 ? false : true;
    },
  },
  watch: {
    "$route.query": {
      handler: function () {
        if (!Object.keys(this.$route.query).length) {
          this.$refs.form.reset();
        }
      },
      deep: true,
      immediate: true,
    },
    deliveryDateFormat() {
      this.model.delivery_date = this.profileById.date_format
        ? this.deliveryDateFormat
        : this.formatDateDDMMYYYY(this.deliveryDateFormat);
      if (this.model.delivery_date) {
        this.model.year = parseDateYYYYMMDD(this.model.delivery_date).split(
          "-"
        )[0];
      }
    },
    profileById() {
      if (
        Object.keys(this.profileById).length > 0 &&
        !this.$route.params.supplier_delivery_note_id
      ) {
        this.model.vat_percentage = this.profileById.vat_percentage;
      }
    },
    supplierLimit(newVal, oldVal) {
      if (newVal !== oldVal)
        this.$store.dispatch("supplier/GetSupplier", {
          page: 1,
          limit: this.supplierLimit,
          order_by: "name_1|asc",
        });
    },
    employeeLimit(newVal, oldVal) {
      if (newVal !== oldVal)
        this.$store.dispatch("employee/GetEmployee", {
          page: 1,
          limit: this.employeeLimit,
          order_by: "name|asc",
        });
    },
    supplierDeliveryNoteById() {
      if (Object.keys(this.supplierDeliveryNoteById).length > 0) {
        this.model = Object.assign({}, this.supplierDeliveryNoteById);
        if (this.model.delivery_date) {
          this.deliveryDateFormat = new Date(this.model.delivery_date)
            .toISOString()
            .substr(0, 10);
          this.model.delivery_date = this.profileById.date_format
            ? new Date(this.model.delivery_date).toISOString().substr(0, 10)
            : this.formatDateDDMMYYYY(this.model.delivery_date);
        }
        if (!this.$route.params.copiedModel) {
          this.relatedPurchaseOrderItems =
            this.model.relatedPurchaseOrderItems.filter(
              (item) => item.related_model_id
            );
          this.relatedSupplierInvoiceItems =
            this.model.relatedSupplierInvoiceItems.filter(
              (item) => item.related_model_id
            );
          this.selectedStatus = this.model.status;
        } else {
          this.model.status = 0;
        }
        this.originModel = Object.assign({}, this.originModel, this.model);
      }
    },
    async moreThanOneUserLogout() {
      if (this.moreThanOneUserLogout && this.model.id) {
        await this.$store.dispatch("PostDocumentOpenAction", {
          table: "SupplierDeliveryNote",
          id: this.model.id,
          is_locked: false,
        });
        this.$store.dispatch("LogOut");
        this.$router.push({ name: "login" });
      }
    },
  },
  async created() {
    // More than one user
    window.addEventListener("beforeunload", this.handler);

    if (this.$route.params.supplier_delivery_note_id) {
      await this.$store.dispatch(
        "supplierDeliveryNote/GetSupplierDeliveryNoteById",
        this.$route.params.supplier_delivery_note_id
      );
      if (
        !this.model.is_locked &&
        this.$route.params.supplier_delivery_note_id
      ) {
        await this.$store.dispatch("PostDocumentOpenAction", {
          table: "SupplierDeliveryNote",
          id: this.$route.params.supplier_delivery_note_id,
          is_locked: true,
        });
      }
    }
    if (this.supplierDeliveryNoteId) {
      this.$store.dispatch("remarks/GetRemarks", {
        where: "document_type|" + 94,
        where_and: "document_id|" + this.supplierDeliveryNoteId,
      });
    }
  },
  async mounted() {
    this.onMounteUpdated();
    this.selectedEmployee = this.user.name;
    let warehouseNameValue = await getAuxiliaryZtableValueByName(20);
    if (Object.keys(warehouseNameValue).length > 0) {
      this.warehouse2 = warehouseNameValue.ztables.sort((a, b) =>
        a.description.toLocaleLowerCase() > b.description.toLocaleLowerCase()
          ? 1
          : -1
      );
    }
  },
  beforeDestroy() {
    if (
      (this.model.id &&
        !this.moreThanOneUserLogout &&
        this.model.is_locked &&
        this.model.user_id == this.user.id) ||
      (this.model.id && !this.model.user_id && !this.moreThanOneUserLogout)
    ) {
      this.$store.dispatch("PostDocumentOpenAction", {
        table: "SupplierDeliveryNote",
        id: this.model.id,
        is_locked: false,
      });
    }
    this.$refs.form ? this.$refs.form.reset() : "";
    this.model = {};
    this.supplier_id = null;
    this.$store.commit("supplier/SET_SUPPLIER_BY_ID", {});
    this.$store.commit(
      "supplierDeliveryNote/SET_SUPPLIER_DELIVERY_NOTE_BY_ID",
      {}
    );
    this.$store.commit("supplier/SET_SUPPLIER", []);
    this.$store.commit("SET_USER_LOGOUT", false);
    window.removeEventListener("beforeunload", this.handler);
  },
  methods: {
    parseDateYYYYMMDD: parseDateYYYYMMDD,
    formatDateDDMMYYYY: formatDateDDMMYYYY,
    todayDate: todayDate,
    async handler(event) {
      if (
        (this.model.id &&
          !this.moreThanOneUserLogout &&
          this.model.is_locked &&
          this.model.user_id == this.user.id) ||
        (this.model.id && !this.model.user_id && !this.moreThanOneUserLogout)
      ) {
        await this.$store.dispatch("PostDocumentOpenAction", {
          table: "SupplierDeliveryNote",
          id: this.model.id,
          is_locked: false,
        });
      }
      event.preventDefault();
    },
    changeWarehouse() {
      if (this.originModel.status === 1) {
        this.saveWarehouse = true;
      }
    },
    async onMounteUpdated() {
      if (this.$route.params.copiedModel) {
        this.model = Object.assign(
          {},
          this.model,
          this.$route.params.copiedModel
        );
        this.originModel = Object.assign({}, this.originModel, this.model);
        this.model.status_one = null;
        this.selectedWarehouse = null;
        this.copyFormLoading = false;
        if (this.model.employee_id) {
          this.selectedEmployee = {
            id: this.model?.employee?.id,
            name: this.model?.employee?.name,
          };
          this.model.employee_id = this.model?.employee_id;
        }
        this.supplier_id = this.model.supplier_id;
        this.selectedCustomer = {
          id: this.model.supplier_id,
          name_1: this.model.supplier.name_1,
        };
        if (this.model.vat_percentage) {
          this.includeVatCharge = true;
        } else {
          this.includeVatCharge = false;
        }
        this.model.supplier_id = this.model.supplier.id;
        this.customerOrderNoRequired =
          this.model.supplier.customer_order_no_compulsory;
        if (this.model.supplierDeliveryNoteItems.length < 1) {
          this.supplierDeliveryNoteItems = [
            {
              selectedProduct: null,
              unit_price: null,
              item_description: null,
              total: null,
            },
          ];
        } else {
          this.supplierDeliveryNoteItems = [];
        }
        this.model.status = 0;
        await this.$store
          .dispatch(
            "supplierDeliveryNote/GetSupplierDeliveryNoteById",
            this.$route.params.copiedModel.id
          )
          .then((res) => {
            if (res) {
              this.model.supplier_name = res.supplier?.name_1;
              this.model.address = res.supplier?.address_1;
              this.model.town = res.supplier?.town_1;
              this.model.vat_percentage = res.vat_percentage;
              this.model.title = res.title;
              this.model.supplier_id = res.supplier?.id;
              this.model.remarks = res.remarks;
            }
          });
        this.deliveryDateFormat = new Date().toISOString().substr(0, 10);
        this.model.delivery_date = this.profileById.date_format
          ? new Date().toISOString().substring(0, 10)
          : this.formatDateDDMMYYYY(this.model.delivery_date);
        this.model.total = 0.0;
        this.model.supplierDeliveryNoteItems.forEach((element) => {
          delete element["created_at"];
          delete element["updated_at"];
          delete element["supplier_delivery_note_id"];
          delete element["id"];
          delete element["supplied"];
          delete element["inventory"];
          delete element["multiple_doc_type"];
          let unit_price_change = this.numberWithCommas(
            element["unit_price"],
            "noFloat"
          );
          let total_change = this.numberWithCommas(element["total"], "noFloat");
          if (element["unit_price"] && !element["quantity"]) {
            element = Object.assign({}, { ...element, quantity: 1 });
          }
          this.supplierDeliveryNoteItems.push(
            Object.assign(
              {},
              {
                ...element,
                total: total_change,
                unit_price: unit_price_change,
              },
              {
                selectedProduct: {
                  item_id: element.item_id,
                  item_code: element.item_code,
                },
              }
            )
          );
        });
        if (this.model.status === 0) {
          this.$nextTick(() => {
            this.$refs.form.validate();
          });
        }
        this.supplierDeliveryNotePriceCalculation();
      } else if (this.$route.params.supplier_delivery_note_id) {
        this.documentNextAndPrevious();
        await this.$store.dispatch(
          "supplierDeliveryNote/GetSupplierDeliveryNoteById",
          this.$route.params.supplier_delivery_note_id
        );
        this.model.supplier_id
          ? this.$store.dispatch(
              "supplier/GetSupplierById",
              this.model.supplier_id
            )
          : "";
        if (this.model.employee_id) {
          this.selectedEmployee = {
            id: this.model?.employee?.id,
            name: this.model?.employee?.name,
          };
          this.model.employee_id = this.model?.employee_id;
        }
        this.supplier_id = this.model?.supplier_id;
        this.selectedCustomer = {
          id: this.model?.supplier_id,
          name_1: this.model.supplier?.name_1,
          fixed_discount: this.model.supplier?.fixed_discount,
        };
        this.model.supplier_id = this.model?.supplier?.id;
        this.customerOrderNoRequired =
          this.model?.supplier?.customer_order_no_compulsory;
        this.includeVatCharge = this.model?.supplier?.vat_charge;
        this.selectedStatus = this.model.status;
        this.selectedWarehouse = this.model.warehouse;
        if (this.model.supplierDeliveryNoteItems.length < 1) {
          this.supplierDeliveryNoteItems = [
            {
              selectedProduct: null,
              unit_price: null,
              item_description: null,
              total: null,
            },
          ];
        } else {
          this.supplierDeliveryNoteItems = [];
        }
        this.model.supplierDeliveryNoteItems.forEach((element) => {
          delete element["created_at"];
          delete element["updated_at"];
          let unit_price_change = this.numberWithCommas(
            element["unit_price"],
            "noFloat"
          );
          let total_change = this.numberWithCommas(element["total"], "noFloat");
          if (element["unit_price"] && !element["quantity"]) {
            element = Object.assign({}, { ...element, quantity: 1 });
          }
          this.supplierDeliveryNoteItems.push(
            Object.assign(
              {},
              {
                ...element,
                total: total_change,
                unit_price: unit_price_change,
              },
              {
                selectedProduct: {
                  item_id: element.item_id,
                  item_code: element.item_code,
                },
              }
            )
          );
        });

        if (this.model.status === 0) {
          this.$refs.form.validate();
          this.supplierDeliveryNotePriceCalculation();
        }
      } else {
        if (this.$route.query.purchaseOrderId) {
          let supplier = await this.$store.dispatch(
            "supplier/GetSupplierById",
            this.$route.query.supplierId
          );
          this.model.supplier = supplier;
          this.supplier_id = this.$route.query.supplierId;
          this.selectedCustomer = {
            id: this.model.supplier?.id,
            name_1: this.model.supplier?.name_1,
          };
          this.model.supplier_id = this.model.supplier.id;
          this.includeVatCharge = this.model.supplier.vat_charge;
          this.customerOrderNoRequired =
            this.model.supplier.customer_order_no_compulsory;
          let purchaseOrder = await this.$store.dispatch(
            "purchaseOrder/GetPurchaseOrderById",
            this.$route.query.purchaseOrderId
          );
          this.model.including_vat = purchaseOrder.including_vat;
          this.model.customer_order_no = this.$route.query.purchaseOrderId;
          this.model.authorized_dealer_no = purchaseOrder.authorized_dealer_no;
          this.model.email = purchaseOrder.email;
          this.model.address = purchaseOrder.address;
          this.model.town = purchaseOrder.town;
          this.model.country = purchaseOrder.country;
          this.model.phone_1 = purchaseOrder.phone_1;
          this.model.phone_2 = purchaseOrder.phone_2;
          this.model.cellular = purchaseOrder.cellular;
          this.model.fax = purchaseOrder.fax;
          this.model.supplier_name = purchaseOrder.supplier_name;
          if (purchaseOrder.employee_id) {
            this.selectedEmployee = {
              id: purchaseOrder.employee?.id,
              name: purchaseOrder.employee?.name,
            };
            this.model.employee_id = purchaseOrder.employee_id;
          }
          this.model.remarks = purchaseOrder.remarks;
          this.model.title = purchaseOrder.title;
          if (this.model.supplierDeliveryNoteItems < 1) {
            this.supplierDeliveryNoteItems = [
              {
                selectedProduct: null,
                unit_price: null,
                item_description: null,
                total: null,
              },
            ];
          } else {
            this.supplierDeliveryNoteItems = [];
          }
          this.model.purchase_order_id = Number(
            this.$route.query.purchaseOrderId
          );
          this.allSelectedProduct = false;
          this.selectedProduct = [];
          this.model.status = 0;
          if (this.model.vat_percentage) {
            this.includeVatCharge = true;
          } else {
            this.includeVatCharge = false;
          }
          this.selectedStatus = this.model.status;
          let orderItemsData = purchaseOrder.purchaseOrderItems;
          orderItemsData.forEach((element) => {
            let purchaseOrderItemId = element.id;
            delete element["created_at"];
            delete element["updated_at"];
            delete element["purchase_order_id"];
            delete element["id"];
            delete element["quotation_item_id"];
            delete element["remainder"];
            delete element["supplied"];
            delete element["inventory"];
            if (element.item_code) {
              let unit_price_change = this.numberWithCommas(
                element["unit_price"],
                "noFloat"
              );
              let total_change = this.numberWithCommas(
                element["total"],
                "noFloat"
              );
              if (element["unit_price"] && !element["quantity"]) {
                element = Object.assign({}, { ...element, quantity: 1 });
              }

              if (this.$route.query.type === "copy-create") {
                console.log("hello");
              } else {
                let total_change_2 =
                  element.remainingQuantity * element.unit_price;
                let total_change_3 =
                  (total_change_2 * element.line_discount) / 100;
                let total_change_4 = total_change_2 - total_change_3;
                let total_change_price = this.numberWithCommas(total_change_4);
                let quantity_change = element["remainingQuantity"]
                  ? element["remainingQuantity"]
                  : element["quantity"];

                if (element.remainingQuantity) {
                  this.supplierDeliveryNoteItems.push(
                    Object.assign(
                      {},
                      {
                        ...element,
                        quantity: quantity_change,
                        total: total_change_price,
                        unit_price: unit_price_change,
                        type: "saveCreate",
                        purchase_order_id: purchaseOrderItemId,
                      },
                      {
                        selectedProduct: {
                          item_id: element.item_id,
                          item_code: element.item_code,
                        },
                      }
                    )
                  );
                  this.selectedProduct.push(
                    Object.assign(
                      {},
                      {
                        ...element,
                        quantity: quantity_change,
                        total: total_change_price,
                        unit_price: unit_price_change,
                        type: "saveCreate",
                        purchase_order_id: purchaseOrderItemId,
                      },
                      {
                        selectedProduct: {
                          item_id: element.item_id,
                          item_code: element.item_code,
                        },
                      }
                    )
                  );
                } else {
                  this.supplierDeliveryNoteItems.push(
                    Object.assign(
                      {},
                      {
                        ...element,
                        quantity: quantity_change,
                        total: total_change,
                        unit_price: unit_price_change,
                        purchase_order_id: purchaseOrderItemId,
                        type: "saveCreate",
                      },
                      {
                        selectedProduct: {
                          item_id: element.item_id,
                          item_code: element.item_code,
                        },
                      }
                    )
                  );
                  this.selectedProduct.push(
                    Object.assign(
                      {},
                      {
                        ...element,
                        quantity: quantity_change,
                        total: total_change,
                        unit_price: unit_price_change,
                        purchase_order_id: purchaseOrderItemId,
                        type: "saveCreate",
                      },
                      {
                        selectedProduct: {
                          item_id: element.item_id,
                          item_code: element.item_code,
                        },
                      }
                    )
                  );
                }
              }
            }
          });

          this.model.discount_percentage = purchaseOrder.discount_percentage;
          this.model.vat_percentage = purchaseOrder.vat_percentage;
          this.supplierDeliveryNotePriceCalculation();
        }
        this.model.year = new Date().getFullYear();
        this.deliveryDateFormat = new Date().toISOString().substr(0, 10);
        this.model.delivery_date = this.profileById.date_format
          ? this.formatDateDDMMYYYY(this.model.delivery_date)
          : new Date().toISOString().substring(0, 10);
      }
      this.$store.dispatch("profile/GetCompanyById");
      this.saveWarehouse = false;
    },
    async documentNextAndPrevious(customerId = null) {
      if (this.supplierDeliveryNoteId) {
        let docPagination = await this.$store.dispatch(
          "GetDocumentPagination",
          {
            type: "supplier_delivery_notes",
            customer_id: customerId,
            document_id: Number(this.supplierDeliveryNoteId),
          }
        );
        this.nextId = docPagination.nextId ? docPagination.nextId : null;
        this.prevId = docPagination.prevId ? docPagination.prevId : null;
      }
    },
    async documentNextAndPreviousBlur(customerId = null) {
      let self = this;
      setTimeout(async () => {
        if (self.supplierDeliveryNoteId) {
          let docPagination = await self.$store.dispatch(
            "GetDocumentPagination",
            {
              type: "supplier_delivery_notes",
              customer_id: customerId,
              document_id: Number(self.supplierDeliveryNoteId),
            }
          );
          self.nextId = docPagination.nextId ? docPagination.nextId : null;
          self.prevId = docPagination.prevId ? docPagination.prevId : null;
        }
      }, 1000);
    },
    async infiniteScrollOnEmployee([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.employeeLimit += 10;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    copySupplierDeliveryNote() {
      this.originModel?.supplierDeliveryNoteItems?.forEach((element) => {
        delete element.quotation_item_id;
        delete element.year;
      });
      let copyModel = this.originModel;
      this.$router.push({
        name: "addSupplierDeliveryNote",
        params: {
          copiedModel: copyModel,
        },
      });
    },
    callBackFunction() {
      this.$router.push({
        name: "SupplierDeliveryNote",
        query: {
          ...this.supplierDeliveryNoteQuery,
        },
      });
    },
    onProductDoubleClick(index) {
      this.productDialog = true;
      this.productDialogIndex = index;
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.supplierLimit += 10;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    redirectOnNextAndPrevious(id) {
      if (id) {
        this.$router.push({
          path: "/supplierDeliveryNote/SupplierDeliveryNoteAction/" + id,
        });
      } else {
        return false;
      }
    },
    redirectOnEditProductPage(itemId) {
      if (itemId) {
        this.$router.push({ path: "/item/ItemAction/" + itemId });
      } else {
        return false;
      }
    },
    redirectOnEditSupplierPage() {
      if (this.selectedCustomer) {
        this.$router.push({
          path: "/supplier/SupplierAction/" + this.selectedCustomer.id,
        });
      } else {
        return false;
      }
    },
    redirectOnEditEmployeePage() {
      if (this.selectedEmployee && this.selectedEmployee.id) {
        this.$router.push({
          path: "/employee/EmployeeAction/" + this.selectedEmployee.id,
        });
      } else {
        return false;
      }
    },
    redirectOnEditPurchaseOrderPage(item) {
      if (item) {
        this.$router.push({
          path: "/purchaseOrder/PurchaseOrderAction/" + item.related_model_id,
        });
      } else {
        return false;
      }
    },
    redirectToPurchaseOrder() {
      if (this.model.customer_order_no) {
        this.$router.push({
          path:
            "/purchaseOrder/PurchaseOrderAction/" +
            this.model.customer_order_no,
        });
      }
    },
    redirectOnEditSupplierInvoicePage(item) {
      if (item) {
        this.$router.push({
          path:
            "/supplierInvoice/supplierInvoiceAction/" + item.related_model_id,
        });
      } else {
        return false;
      }
    },
    numberFromCommas(x) {
      return x ? x.toString().replace(/,/g, "") : null;
    },
    numberWithCommas(x, type = "float") {
      return x
        ? type === "float"
          ? parseFloat(x)
              .toFixed(2)
              .toString()
              .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
          : x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
        : type === "float"
        ? (0).toFixed(2)
        : x;
    },
    onEmployeeChange(item) {
      if (item && typeof item === "object" && Object.keys(item).length > 0) {
        this.selectedEmployee = { id: item.id, name: item?.name };
        this.model.employee_id = item.id;
      } else {
        this.selectedEmployee = null;
        this.model.employee_id = null;
      }
    },
    openCustomerAdditionalDetailModel() {
      this.customerAdditionalDetail.authorized_dealer_no =
        this.model.authorized_dealer_no;
      this.customerAdditionalDetail.email = this.model.email;
      this.customerAdditionalDetail.country = this.model.country;
      this.customerAdditionalDetail.phone_1 = this.model.phone_1;
      this.customerAdditionalDetail.phone_2 = this.model.phone_2;
      this.customerAdditionalDetail.cellular = this.model.cellular;
      this.customerAdditionalDetail.fax = this.model.fax;
      this.customerDetailDialog = true;
    },
    async saveCustomerAdditionalDetail() {
      this.model.authorized_dealer_no =
        this.customerAdditionalDetail.authorized_dealer_no;
      this.model.email = this.customerAdditionalDetail.email;
      this.model.country = this.customerAdditionalDetail.country;
      this.model.phone_1 = this.customerAdditionalDetail.phone_1;
      this.model.phone_2 = this.customerAdditionalDetail.phone_2;
      this.model.cellular = this.customerAdditionalDetail.cellular;
      this.model.fax = this.customerAdditionalDetail.fax;
      if (this.$route.params.supplier_delivery_note_id) {
        this.isProgressCircular = true;
        await this.$store.dispatch(
          "supplierDeliveryNote/EditSupplierDeliveryNote",
          {
            supplier_delivery_note_id:
              this.$route.params.supplier_delivery_note_id,
            supplierDeliveryNote: {
              ...this.customerAdditionalDetail,
              main_number: 94,
            },
          }
        );
        this.isProgressCircular = false;
      }
    },
    lessThanValidation(fieldValue, typeValue, sizeValue, v) {
      return (
        !v ||
        (v && String(v).length <= sizeValue) ||
        this.$t("formRules.lengthValidMessage", {
          fieldName: fieldValue,
          type: typeValue,
          size: sizeValue,
        })
      );
    },
    async onSupplierChange(customer) {
      if (
        customer &&
        typeof customer === "object" &&
        Object.keys(customer).length > 0
      ) {
        let customerModel = {};
        this.selectedCustomer = {
          id: customer.id,
          name_1: customer?.name_1,
          fixed_discount: customer?.fixed_discount,
        };
        this.selectedEmployee = null;
        this.model.employee_id = null;
        this.model.supplier_id = customer.id;
        this.supplier_id = customer.id;
        this.includeVatCharge = customer.vat_charge;
        this.model.vat_percentage = this.profileById.vat_percentage;
        this.customerOrderNoRequired = customer.customer_order_no_compulsory;
        if (this.profileById.occasional_customer_no !== String(customer.id)) {
          this.model.supplier_name = customer?.name_1;
          if (customer.employee_id) {
            customer.employee_id
              ? await this.$store.dispatch(
                  "employee/GetEmployeeById",
                  customer.employee_id
                )
              : "";
            this.selectedEmployee = {
              id: this.employeeById.id,
              name: this.employeeById.name,
            };
            this.model.employee_id = customer.employee_id;
          } else {
            this.selectedEmployee = this.user.name;
          }
          customerModel.authorized_dealer_no = customer.authorized_dealer_no;
          customerModel.email = customer.email;
          customerModel.address = customer.address_1;
          customerModel.town = customer.town_1;
          customerModel.country = customer.country;
          customerModel.phone_1 = customer.phone_1;
          customerModel.phone_2 = customer.phone_2;
          customerModel.cellular = customer.cellular_1;
          customerModel.fax = customer.fax;
        } else {
          this.model.supplier_name = "";
          customerModel.authorized_dealer_no = "";
          customerModel.email = "";
          customerModel.address = "";
          customerModel.town = "";
          customerModel.country = "";
          customerModel.phone_1 = "";
          customerModel.phone_2 = "";
          customerModel.cellular = "";
          customerModel.fax = "";
        }
        this.model = Object.assign({}, this.model, customerModel);
        if (this.model.status === 0) {
          this.$refs.form.validate();
        }
      } else {
        this.selectedCustomer = null;
        this.model.supplier_id = null;
      }
      this.supplierDeliveryNotePriceCalculation();
    },
    itemPriceCalculation(index) {
      let item = this.supplierDeliveryNoteItems[index];
      const decimalPoints = this.profileById.decimal_points || 2;
      let checkUnitPrice = item.unit_price;
      if (checkUnitPrice && this.$refs["unit_price" + index]) {
        const pos =
          this.$refs["unit_price" + index][0].$el.querySelector("input")
            .selectionStart + 1;
        if (
          checkUnitPrice.length !=
          this.numberWithCommas(
            this.numberFromCommas(item.unit_price),
            "noFloat"
          ).length
        ) {
          this.$nextTick(() => {
            this.$refs["unit_price" + index][0]?.$el
              ? (this.$refs["unit_price" + index][0].$el.querySelector(
                  "input"
                ).selectionEnd = pos)
              : "";
          });
        } else {
          this.$nextTick(() => {
            this.$refs["unit_price" + index][0]?.$el
              ? (this.$refs["unit_price" + index][0].$el.querySelector(
                  "input"
                ).selectionEnd = pos - 1)
              : "";
          });
        }
      }

      // price format of total price value
      let checkTotal = item.total;
      if (checkTotal && this.$refs["total_price" + index]) {
        const pos =
          this.$refs["total_price" + index][0].$el.querySelector("input")
            .selectionStart + 1;
        if (
          checkTotal.length !=
          this.numberWithCommas(this.numberFromCommas(item.total), "noFloat")
            .length
        ) {
          this.$nextTick(() => {
            this.$refs["total_price" + index][0]?.$el
              ? (this.$refs["total_price" + index][0].$el.querySelector(
                  "input"
                ).selectionEnd = pos)
              : "";
          });
        } else {
          this.$nextTick(() => {
            this.$refs["total_price" + index][0]?.$el
              ? (this.$refs["total_price" + index][0].$el.querySelector(
                  "input"
                ).selectionEnd = pos - 1)
              : "";
          });
        }
      }
      let unitPriceNumber = parseFloat(this.numberFromCommas(item.unit_price));

      this.supplierDeliveryNoteItems[index].unit_price = this.numberWithCommas(
        unitPriceNumber.toFixed(decimalPoints),
        "noFloat"
      );
      this.supplierDeliveryNoteItems[index].total = this.numberWithCommas(
        this.numberFromCommas(item.total),
        "noFloat"
      );
      this.$nextTick(() => {
        if (this.$refs.form && this.$refs["unit_price" + index][0].validate()) {
          item.unit_price = unitPriceNumber.toFixed(decimalPoints);
        }
        if (
          this.$refs.form &&
          this.$refs["total_price" + index][0].validate()
        ) {
          item.total = this.numberFromCommas(item.total);
        }
        if (parseFloat(item.unit_price)) {
          item.total =
            parseFloat(item.quantity) && parseFloat(item.unit_price)
              ? (
                  parseFloat(item.quantity) * parseFloat(item.unit_price)
                ).toFixed(2)
              : parseFloat(item.unit_price)
              ? parseFloat(item.unit_price).toFixed(2)
              : (0).toFixed(2);

          // item total calculation with discount
          item.total = parseFloat(item.line_discount)
            ? (
                parseFloat(item.total) -
                (parseFloat(item.total) * parseFloat(item.line_discount)) / 100
              ).toFixed(2)
            : parseFloat(item.total).toFixed(2);

          this.supplierDeliveryNoteItems[index].unit_price =
            this.numberWithCommas(
              unitPriceNumber.toFixed(decimalPoints),
              "noFloat"
            );

          this.supplierDeliveryNoteItems[index].total = this.numberWithCommas(
            this.numberFromCommas(item.total),
            "noFloat"
          );

          if (
            this.supplierDeliveryNoteItems[index].unit_price &&
            !item.quantity
          ) {
            item.quantity = 1;
          }
        } else {
          this.supplierDeliveryNoteItems[index].total = this.numberWithCommas(
            this.numberFromCommas(item.total),
            "noFloat"
          );
        }

        this.supplierDeliveryNoteItems[index] = item;

        // total calculation on quotation
        this.supplierDeliveryNotePriceCalculation();
      });
    },
    supplierDeliveryNotePriceCalculation() {
      // total item's calculation
      let self = this;
      if (!this.supplierDeliveryNoteId && this.$route.params.copiedModel) {
        if (this.$route.params.copiedModel.including_vat) {
          this.model.total =
            this.supplierDeliveryNoteItems.length > 0
              ? this.supplierDeliveryNoteItems
                  .reduce(function (total, item) {
                    return parseFloat(self.numberFromCommas(item.total))
                      ? parseFloat(total) +
                          parseFloat(self.numberFromCommas(item.total))
                      : total + 0;
                  }, 0)
                  .toFixed(2) /
                (self.model.vat_percentage / 100 + 1)
              : 0;
        } else {
          this.model.total =
            this.supplierDeliveryNoteItems.length > 0
              ? this.supplierDeliveryNoteItems
                  .reduce(function (total, item) {
                    return parseFloat(self.numberFromCommas(item.total))
                      ? parseFloat(total) +
                          parseFloat(self.numberFromCommas(item.total))
                      : total + 0;
                  }, 0)
                  .toFixed(2)
              : 0;
        }
      } else {
        if (
          (!this.supplierDeliveryNoteId &&
            this.profileById.supplier_delivery_notes) ||
          (this.supplierDeliveryNoteId && this.model.including_vat)
        ) {
          this.model.total =
            this.supplierDeliveryNoteItems.length > 0
              ? this.supplierDeliveryNoteItems
                  .reduce(function (total, item) {
                    return parseFloat(self.numberFromCommas(item.total))
                      ? parseFloat(total) +
                          parseFloat(self.numberFromCommas(item.total))
                      : total + 0;
                  }, 0)
                  .toFixed(2) /
                (self.model.vat_percentage / 100 + 1)
              : 0;
        } else {
          this.model.total =
            this.supplierDeliveryNoteItems.length > 0
              ? this.supplierDeliveryNoteItems
                  .reduce(function (total, item) {
                    return parseFloat(self.numberFromCommas(item.total))
                      ? parseFloat(total) +
                          parseFloat(self.numberFromCommas(item.total))
                      : total + 0;
                  }, 0)
                  .toFixed(2)
              : 0;
        }
      }

      // discount amount calculation
      this.model.discount_percentage = parseFloat(
        this.model.discount_percentage
      )
        ? parseFloat(this.model.discount_percentage).toFixed(2)
        : null;
      this.model.discount = parseFloat(this.model.discount_percentage)
        ? (
            (parseFloat(this.model.total) *
              parseFloat(this.model.discount_percentage)) /
            100
          ).toFixed(2)
        : (0).toFixed(2);

      // total amount after discount calculation
      this.model.after_discount = parseFloat(this.model.discount)
        ? (
            parseFloat(this.model.total) - parseFloat(this.model.discount)
          ).toFixed(2)
        : parseFloat(this.model.total).toFixed(2);

      this.model.vat_percentage = parseFloat(this.model.vat_percentage)
        ? parseFloat(this.model.vat_percentage)
        : null;
      this.model.vat =
        parseFloat(this.model.vat_percentage) && this.includeVatCharge
          ? (
              (parseFloat(this.model.after_discount) *
                parseFloat(this.model.vat_percentage)) /
              100
            ).toFixed(2)
          : (0).toFixed(2);
      // grand total amount calculation
      this.model.grand_total = parseFloat(this.model.vat)
        ? (
            parseFloat(this.model.after_discount) + parseFloat(this.model.vat)
          ).toFixed(2)
        : parseFloat(this.model.after_discount).toFixed(2);

      // after vat grand total rounding if total rounding is true
      if (this.profileById.total_rounding) {
        let total = this.model.total;
        let discount = this.model.discount;
        let vatPercentage = this.model.vat_percentage;
        let grandTotal = parseInt(this.model.grand_total);
        let roundingDiff = this.model.grand_total - grandTotal;
        let findRoundNumber = parseFloat(
          roundingDiff / (1 + vatPercentage / 100)
        ).toFixed(2);
        if (findRoundNumber <= 0.5) {
          this.model.discount = (
            parseFloat(discount) + parseFloat(findRoundNumber)
          ).toFixed(2);
          this.model.after_discount = (
            parseFloat(total) - parseFloat(this.model.discount)
          ).toFixed(2);
          this.model.vat = (
            parseFloat(grandTotal) - parseFloat(this.model.after_discount)
          ).toFixed(2);
          this.model.grand_total = parseFloat(grandTotal).toFixed(2);
        } else {
          let roundingDiff = parseFloat(
            (grandTotal + 1 - this.model.grand_total) * -1
          ).toFixed(2);
          this.model.discount = (
            parseFloat(discount) +
            parseFloat(roundingDiff / (1 + vatPercentage / 100))
          ).toFixed(2);
          this.model.after_discount = (
            parseFloat(total) - parseFloat(this.model.discount)
          ).toFixed(2);
          this.model.vat = (
            parseFloat(grandTotal) +
            1 -
            parseFloat(this.model.after_discount)
          ).toFixed(2);
          this.model.grand_total = (parseFloat(grandTotal) + 1).toFixed(2);
        }
      }
    },
    addRow() {
      this.supplierDeliveryNoteItems.push({
        index: this.supplierDeliveryNoteItems.length,
        selectedProduct: null,
        unit_price: null,
        item_description: null,
        total: null,
      });
      // scroll on new added row
      let newRow = document.querySelector(".newRow");
      let wrapper = document.querySelector(
        ".item-table div.v-data-table__wrapper"
      );
      this.$vuetify.goTo(newRow, { container: wrapper });
    },
    copyData(i) {
      delete this.supplierDeliveryNoteItems[i].id;
      delete this.supplierDeliveryNoteItems[i].supplier_delivery_note_id;
      delete this.supplierDeliveryNoteItems[i].created_at;
      delete this.supplierDeliveryNoteItems[i].updated_at;
      delete this.supplierDeliveryNoteItems[i].line_number;
      this.supplierDeliveryNoteItems.push({
        ...this.supplierDeliveryNoteItems[i],
      });
    },
    deleteRow(index) {
      this.selectedProduct.splice(index, 1);
      this.supplierDeliveryNoteItems.splice(index, 1);
      this.supplierDeliveryNotePriceCalculation();
    },
    suppliedQty() {
      this.remainingSuppliedDialogBox = true;
    },
    async onProductChange(item, index, type) {
      if (item && type === "dropdown") {
        this.supplierDeliveryNoteItems[index].index = index;
        this.supplierDeliveryNoteItems[index].selectedProduct = {
          item_id: item.id,
          item_code: item.item_code,
        };

        if (!item?.supplierPrice) {
          this.supplierDeliveryNoteItems[index].unit_price = item.unit_price;
        }

        this.supplierDeliveryNoteItems[index].item_id = item.id;
        this.supplierDeliveryNoteItems[index].quantity = item.quantity;
        if (this.selectedCustomer?.fixed_discount) {
          this.supplierDeliveryNoteItems[index].line_discount =
            this.selectedCustomer?.fixed_discount;
        } else if (item.supplierDiscount) {
          this.supplierDeliveryNoteItems[index].line_discount =
            item.supplierDiscount;
        } else if (item.supplierPrice) {
          this.supplierDeliveryNoteItems[index].unit_price = item.supplierPrice;
        } else if (item.fixed_discount) {
          this.supplierDeliveryNoteItems[index].line_discount =
            item.fixed_discount;
        } else {
          this.supplierDeliveryNoteItems[index].line_discount = null;
        }
        this.supplierDeliveryNoteItems[index].item_code = item.item_code;
        this.supplierDeliveryNoteItems[index].item_description =
          item.item_description;
        this.selectedProduct[index] = this.supplierDeliveryNoteItems[index];
        this.$refs.form.validate();
        this.itemPriceCalculation(index);
      } else {
        if (item && Object.keys(item).length > 0) {
          await this.$store
            .dispatch("product/GetSupplierItemDiscountOrPrice", {
              supplier_id: this.selectedCustomer.id,
              item_id: item.id,
            })
            .then(async (res) => {
              this.itemDiscount = await res;
            });

          this.supplierDeliveryNoteItems[index].index = index;
          this.supplierDeliveryNoteItems[index].selectedProduct = {
            item_id: item.id,
            item_code: item.item_code,
          };

          if (!this.itemDiscount && !this.itemDiscount.supplierPrice) {
            this.supplierDeliveryNoteItems[index].unit_price =
              item.purchase_price;
          }

          this.supplierDeliveryNoteItems[index].item_id = item.id;
          this.supplierDeliveryNoteItems[index].quantity = item.quantity;
          if (this.selectedCustomer?.fixed_discount) {
            this.supplierDeliveryNoteItems[index].line_discount =
              this.selectedCustomer?.fixed_discount;
          } else if (this.itemDiscount && this.itemDiscount.supplierDiscount) {
            this.supplierDeliveryNoteItems[index].line_discount =
              this.itemDiscount.supplierDiscount;
          } else if (this.itemDiscount && this.itemDiscount.supplierPrice) {
            this.supplierDeliveryNoteItems[index].unit_price =
              this.itemDiscount.supplierPrice;
          } else {
            this.supplierDeliveryNoteItems[index].line_discount = null;
          }
          this.supplierDeliveryNoteItems[index].item_code = item.item_code;
          this.supplierDeliveryNoteItems[index].item_description =
            item.description;
          this.selectedProduct[index] = this.supplierDeliveryNoteItems[index];
          this.$refs.form.validate();
          this.itemPriceCalculation(index);
        } else {
          this.supplierDeliveryNoteItems[index].index = index;
          this.supplierDeliveryNoteItems[index].selectedProduct = null;
          this.supplierDeliveryNoteItems[index].unit_price = null;
          this.supplierDeliveryNoteItems[index].item_id = null;
          this.supplierDeliveryNoteItems[index].quantity = null;
          this.supplierDeliveryNoteItems[index].line_discount = null;
          this.supplierDeliveryNoteItems[index].item_code = null;
          this.supplierDeliveryNoteItems[index].item_description = null;
          this.selectedProduct[index] = this.supplierDeliveryNoteItems[index];
          this.$refs.form.validate();
          this.itemPriceCalculation(index);
        }
      }
    },
    async saveSupplierDeliveryNote() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let data;
        let supplierDeliveryNoteItems;
        if (!this.$route.params.supplier_delivery_note_id) {
          supplierDeliveryNoteItems = this.supplierDeliveryNoteItems.filter(
            (item) => {
              if (item.item_id || item.id) {
                delete item["selectedProduct"];
                delete item["index"];
                delete item["remainingQuantity"];
                item.quantity = item.quantity === "" ? null : item.quantity;
                item.line_discount =
                  item.line_discount === "" ? null : item.line_discount;
                item.unit_price =
                  this.numberFromCommas(item.unit_price) === ""
                    ? null
                    : this.numberFromCommas(item.unit_price);
                item.total =
                  this.numberFromCommas(item.total) === ""
                    ? null
                    : this.numberFromCommas(item.total);
                return item;
              }
            }
          );
        } else {
          supplierDeliveryNoteItems = this.supplierDeliveryNoteItems.filter(
            (item) => {
              if (item.item_id || item.id) {
                delete item["selectedProduct"];
                delete item["index"];
                delete item["remainingQuantity"];
                item.quantity = item.quantity === "" ? null : item.quantity;
                item.line_discount =
                  item.line_discount === "" ? null : item.line_discount;
                item.unit_price =
                  this.numberFromCommas(item.unit_price) === ""
                    ? null
                    : this.numberFromCommas(item.unit_price);
                item.total =
                  this.numberFromCommas(item.total) === ""
                    ? null
                    : this.numberFromCommas(item.total);
                return item;
              }
            }
          );
        }
        this.model.data = supplierDeliveryNoteItems;
        this.$nextTick();
        this.model.delivery_date = this.profileById.date_format
          ? this.parseDateYYYYMMDD(this.model.delivery_date)
          : this.model.delivery_date;
        if (
          this.selectedStatus &&
          Object.keys(this.selectedStatus).length > 0
        ) {
          this.model.status = this.selectedStatus.id;
        } else {
          this.model.status = this.selectedStatus;
        }

        if (
          this.selectedWarehouse &&
          Object.keys(this.selectedWarehouse).length > 0
        ) {
          this.model.warehouse = this.selectedWarehouse.id;
        }
        if (
          this.$route.query.supplierDeliveryNoteId &&
          !this.model.including_vat &&
          !this.model.id
        ) {
          this.model.including_vat = this.supplierDeliveryNoteById.including_vat
            ? 1
            : 0;
        } else if (!this.model.id) {
          this.model.including_vat = this.profileById.supplier_delivery_notes
            ? 1
            : 0;
        }

        this.model.main_number = 94;
        if (this.$route.query.purchaseOrderId) {
          this.model.related_number = 16;
        }
        this.model.delivery_note_no = Number(this.model.delivery_note_no);

        if (this.$route.query.purchaseOrderId) {
          if (this.purchaseOrderById.status == 0) {
            await this.$store.dispatch("purchaseOrder/EditPurchaseOrder", {
              purchase_order_id: this.$route.query.purchaseOrderId,
              purchaseOrder: { status: 1 },
            });
          }
        }
        if (this.model.data.length > 0) {
          if (this.$route.params.supplier_delivery_note_id) {
            let saveData = changedFormData(this.model, this.originModel);
            this.isProgressCircular = true;
            saveData.supplier_id = this.model.supplier_id;
            saveData.year = this.model.year;
            data = await this.$store
              .dispatch("supplierDeliveryNote/EditSupplierDeliveryNote", {
                supplier_delivery_note_id:
                  this.$route.params.supplier_delivery_note_id,
                supplierDeliveryNote: saveData,
              })
              .catch((e) => {
                this.deliveryNoteNoDialog = true;
                this.loading = false;
                console.log(e);
              });
            if (data) {
              this.$refs.form.reset();
              this.loading = false;
              await this.onMounteUpdated();
            }
            this.isProgressCircular = false;
          } else if (this.$route.params.copiedModel) {
            let copyModel = this.model;
            copyModel.data.forEach((element) => {
              delete element.quotation_item_id;
            });
            delete copyModel.employee;
            delete copyModel.created_at;
            delete copyModel.id;
            delete copyModel.updated_at;
            copyModel.including_vat = this.originModel.including_vat;
            copyModel.status = 1;
            data = await this.$store
              .dispatch(
                "supplierDeliveryNote/SetSupplierDeliveryNote",
                copyModel
              )
              .catch((e) => {
                this.deliveryNoteNoDialog = true;
                delete copyModel.delivery_note_no;
                this.loading = false;
                console.log(e);
              });
            if (data) {
              this.$refs.form.reset();
              this.loading = false;
              if (this.accessRight.includes("edit")) {
                await this.$router
                  .push(
                    "/supplierDeliveryNote/editSupplierDeliveryNote/" +
                      data.data.id
                  )
                  .catch(() => {});
              } else {
                this.callBackFunction();
              }
            }
          } else {
            this.model.status = 1;
            data = await this.$store
              .dispatch(
                "supplierDeliveryNote/SetSupplierDeliveryNote",
                this.model
              )
              .catch((e) => {
                this.deliveryNoteNoDialog = true;
                this.model.status = 0;
                this.model.data.forEach((obj) => {
                  obj.selectedProduct = {
                    item_id: obj.item_id,
                    item_code: obj.item_code,
                  };
                });
                delete this.model.delivery_note_no;
                this.loading = false;
                console.log(e);
              });
            if (data) {
              this.$refs.form.reset();
              this.loading = false;
              if (this.accessRight.includes("edit")) {
                await this.$router
                  .push(
                    "/supplierDeliveryNote/editSupplierDeliveryNote/" +
                      data.data.id
                  )
                  .catch(() => {});
              } else {
                this.callBackFunction();
              }
            }
          }
        } else {
          this.message = "formRules.productItemAlert";
          this.alert = true;
          this.loading = false;
        }
      }
    },
    async saveAndCreate(type) {
      if (
        this.model &&
        this.model.supplierDeliveryNoteItems &&
        this.model.supplierDeliveryNoteItems.every(
          (ele) =>
            ele.remainingQuantity === 0 || ele.remainingQuantity === undefined
        )
      ) {
        this.suppliedDialogBox = true;
      } else {
        try {
          await this.$store.dispatch("quotation/CheckRemainingQty", {
            data: this.model.supplierDeliveryNoteItems,
            document_type:
              type === "supplierInvoice" ? "Supplier_Invoice" : undefined,
          });
          this.suppliedDialogBox = false;
          let data;
          if (this.$refs.form.validate() && !this.isFieldReadable) {
            this.loading = true;
            let supplierDeliveryNoteItems;
            if (!this.$route.params.supplier_delivery_note_id) {
              supplierDeliveryNoteItems = this.selectedProduct.filter(
                (item) => {
                  if (item.item_id || item.id) {
                    delete item["selectedProduct"];
                    delete item["index"];
                    delete item["delivery_date"];
                    item.quantity = item.quantity === "" ? null : item.quantity;
                    item.line_discount =
                      item.line_discount === "" ? null : item.line_discount;
                    item.unit_price =
                      this.numberFromCommas(item.unit_price) === ""
                        ? null
                        : this.numberFromCommas(item.unit_price);
                    item.total =
                      this.numberFromCommas(item.total) === ""
                        ? null
                        : this.numberFromCommas(item.total);
                    return item;
                  }
                }
              );
            } else {
              supplierDeliveryNoteItems = this.supplierDeliveryNoteItems.filter(
                (item) => {
                  if (item.item_id || item.id) {
                    delete item["selectedProduct"];
                    delete item["index"];
                    delete item["delivery_date"];
                    delete item["supplied"];
                    delete item["remainingQuantity"];
                    item.quantity = item.quantity === "" ? null : item.quantity;
                    item.line_discount =
                      item.line_discount === "" ? null : item.line_discount;
                    item.unit_price =
                      this.numberFromCommas(item.unit_price) === ""
                        ? null
                        : this.numberFromCommas(item.unit_price);
                    item.total =
                      this.numberFromCommas(item.total) === ""
                        ? null
                        : this.numberFromCommas(item.total);
                    return item;
                  }
                }
              );
            }
            this.model.data = supplierDeliveryNoteItems;
            this.$nextTick();
            if (
              this.selectedStatus &&
              Object.keys(this.selectedStatus).length > 0
            ) {
              this.model.status = this.selectedStatus.id;
            } else {
              this.model.status = this.selectedStatus;
            }
            if (
              this.selectedGroup &&
              Object.keys(this.selectedGroup).length > 0
            ) {
              this.model.status_one = this.selectedGroup.id;
            }
            this.model.main_number = 16;

            if (this.model.data.length > 0) {
              if (this.$route.params.supplier_delivery_note_id) {
                let saveData = changedFormData(this.model, this.originModel);
                this.isProgressCircular = true;
                data = await this.$store
                  .dispatch("supplierDeliveryNote/EditSupplierDeliveryNote", {
                    supplier_delivery_note_id:
                      this.$route.params.supplier_delivery_note_id,
                    supplierDeliveryNote: saveData,
                  })
                  .catch((e) => {
                    this.loading = false;
                    console.log(e);
                  });
                this.isProgressCircular = false;
              } else {
                data = await this.$store
                  .dispatch(
                    "supplierDeliveryNote/SetSupplierDeliveryNote",
                    this.model
                  )
                  .catch((e) => {
                    this.loading = false;
                    console.log(e);
                  });
              }
              if (data) {
                this.$refs.form.reset();
                this.$router.push("/supplierDeliveryNote");
              }
            } else {
              this.message = "formRules.productItemAlert";
              this.alert = true;
              this.loading = false;
            }
            if (type === "supplierInvoice") {
              this.$router.push({
                path: "/addSupplierInvoice",
                query: {
                  supplierDeliveryNoteId: JSON.parse(data.config.data).data[0]
                    .supplier_delivery_note_id,
                  supplierId: this.model.supplier_id,
                },
              });
            }
          } else if (this.isFieldReadable) {
            if (type === "supplierInvoice") {
              this.$router.push({
                path: "/addSupplierInvoice",
                query: {
                  supplierDeliveryNoteId:
                    this.$route.params.supplier_delivery_note_id,
                  supplierId: this.model.supplier_id,
                },
              });
            }
          } else {
            this.message = "formRules.formAlert";
            this.alert = true;
          }
        } catch (error) {
          console.log(error, "error");
          this.suppliedDialogBox = true;
        }
      }
    },
    onChangeDialog() {
      this.onMounteUpdated();
      this.deliveryDateFormat = new Date(this.model.delivery_date)
        .toISOString()
        .substr(0, 10);
      this.model.delivery_date = this.profileById.date_format
        ? this.formatDateDDMMYYYY(this.model.delivery_date)
        : new Date(this.model.delivery_date).toISOString().substr(0, 10);
    },
    async deleteItem() {
      this.isProgressCircular = true;
      await this.$store
        .dispatch(
          "supplierDeliveryNote/DeleteSupplierDeliveryNoteById",
          this.$route.params.supplier_delivery_note_id
        )
        .then((res) => {
          if (res.data.type && res.data.type === "delete") {
            this.cancelNotDialog = true;
            this.isProgressCircular = false;
          } else if (res.data.type && res.data.type === "list") {
            this.callBackFunction();
          } else {
            this.$router
              .push(
                "/supplierDeliveryNote/editSupplierDeliveryNote/" +
                  this.$route.params.supplier_delivery_note_id
              )
              .catch(() => {});
            this.model.status = 2;
            this.originModel.status = 2;
            this.selectedStatus = 2;
            this.isProgressCircular = false;
          }
        });
    },
  },
};
</script>

<style scoped>
::v-deep .custom-table ::-webkit-scrollbar {
  width: 12px;
}
::v-deep .yourRowClass.disabled {
  pointer-events: none;
  opacity: 0.5;
}
</style>
